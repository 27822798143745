let metrics = {};
let valueData = null;
let socket = null;
let masterKey = null;
function enableConnection(eventName) {
    if (socket === null || socket.readyState === WebSocket.CLOSED) {
        socket = new WebSocket('wss://event-service.idcrealestate.com/ws/track-event');
    }
    console.log("eventname", eventName);

    // socket connection
    socket.onopen = () => {

        sendEventData(eventName);
    };
    socket.onmessage = (e) => {
        masterKey = e.data;
        setMasterId(); // Get or create userId
    };

    socket.onclose = () => {
    };

    socket.onerror = (error) => {
        console.error("Websocket error:", error);
    };
}
document.addEventListener('DOMContentLoaded', () => {
    const timing = window.performance.timing;
    enableConnection('PageLoad');
    valueData = 0;
    setTimeout(() => {
        metrics = {
            navigationStart: formatAbsoluteTime(timing.navigationStart),
            pageLoadTime: validateAndFormatTime(timing.loadEventEnd, timing.navigationStart),
            timeToFirstByte: validateAndFormatTime(timing.responseStart, timing.navigationStart),
        };
    }, 10);


});
function formatAbsoluteTime(ms) {
    if (!ms || ms < 0) return "0.00";
    const date = new Date(ms);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
}

function validateAndFormatTime(endTime, startTime) {
    if (!endTime || !startTime || endTime <= startTime) return "0.00"; // Handle invalid or missing values
    const difference = (endTime - startTime) / 1000; // Convert to seconds
    return `${difference.toFixed(2)} `;
}
function getPageName() {
    const url = window.location.href.toLowerCase();
    if (url.includes('/property-search-map')) return "PropertyMapSearch";
    if (document.querySelector('.detailpage')) return "PropertyDetail";
    if (url.includes('/real-estate-agent')) return "AgentDetail";
    if (url.includes('/community')) return "CommunityDetail";
    if (url.includes('/neighborhood')) return "NeighborhoodDetail";
    if (url.includes('/real-estate-offices')) return "OfficeResult";
    if (url.includes('/real-estate-office')) return "OfficeDetail";
    if (url.includes('/real-estate-agents')) return "AgentResult";
    if (url.includes('/real-estate-agent')) return "AgentDetail";
    if (url.includes('/market-trends')) return "MarketTrends";
    return "HomePage";
}
function GetFormJson() {
    var formData = {};
    formData["PAGENAME"] = getPageName();
    const clientElement = document.querySelector('[data-pcode]');
    const dataPcode = clientElement ? clientElement.getAttribute('data-pcode') : "IBP";
    formData["CLIENT"] = dataPcode;
    formData["PAGELOADTIME"] = metrics ? metrics.pageLoadTime : "0.00";
    formData["NAVIGATIONSTART"] = metrics ? metrics.navigationStart : "0.00";
    formData["SFVSCORE"] = valueData ? valueData.toString() : "0";
    if (formData["PAGENAME"] == "PropertyDetail") {

        document.querySelectorAll('script[type="application/ld+json"]').forEach((item, index) => {
            const data = JSON.parse(item.innerText);
            const currenturl = window.location.href.toLowerCase();
            const pathSegments = new URL(currenturl).pathname.split('/');
            const typeValue = pathSegments[1];
            const mlsBoard = pathSegments[2]
            const mlsBoardNumber = pathSegments[3]
            if (index === 0) {
                formData.PRICE = data.offers && data.offers.price ? data.offers.price : null;
                formData.PROPERTYNAME = data.name ? data.name : null;
            }
            if (index === 1) {
                formData.ROOMS = data.numberOfRooms ? data.numberOfRooms : null;
                formData.BATHS = data.numberOfBathroomsTotal ? data.numberOfBathroomsTotal : null;
                
            }
            formData.TYPE = typeValue.replace('-', ' ');
            formData.MLSBOARD = mlsBoard
            formData.MLSNUMBER = mlsBoardNumber
        });


    }
    return formData;
}
//send data using websocket
function sendEventData(eventName, additionalData = {}) {
    const hostUrl = window.location.host.toString().toLowerCase();
    const rawUrl = window.location.href.toString().toLowerCase();
    const queryParams = new URLSearchParams(window.location.search).toString().toLowerCase();
    const clientElement = document.querySelector('[data-pcode]');
    const dataPcode = clientElement ? clientElement.getAttribute('data-pcode') : "IBP";
    const eventData = {
        eventname: eventName,
        eventlabel: "PageLoad",
        info: {
            host: hostUrl,
            rawUrl: rawUrl,
            queryParams: queryParams,
            additionaljson: GetFormJson(),
        },
        client: dataPcode
    };

    if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(eventData));
    } else {
        console.warn('WSS_NO_CONN');
    }
}
function setRandomId() {
    return '_' + Math.random().toString(36).substr(2, 9);
}
(function () {

    var sfvState = {
        events: {},
        counts: {},
        values: {}
    };
    var __sfvScore = {};
    var throttling = 10;
    function update(type, score) {
        __sfvScore[type] = score;
        setScoreStorage('SFVTRACKER', btoa(JSON.stringify(__sfvScore)));
    }
    function setScoreStorage(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        let parameters = JSON.parse(atob(cValue));
        valueData = computeScore(parameters);
        let sfvScore = localStorage.getItem("SFVSCORE") ? localStorage.getItem("SFVSCORE") : 0;
        localStorage.setItem("SFVSCORE", valueData)
        if (sfvScore != valueData && valueData > 0) {
            //console.log("TrackEvent",valueData);
           // enableConnection("TrackScore");
        }
    }
    function computeScore(parameters) {
        let score = 0;
        for (let key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                // Pass both the key and its value to computeScoreForParam
                score += computeScoreForParam(key, parameters[key]);
            }
        }
        return score;
    }
    function computeScoreForParam(key, value) {
        return value * getComputingConstantsForParams(key);
    }
    function getComputingConstantsForParams(type) {
        switch (type.toLowerCase()) {
            case "automation":
                return -1000;
            case "window":
            case "scroll":
            case "keydown":
            case "touchmove":
            case "mousemove":
                return 5;
            case "click":
                return 10;
            default:
                return 1;
        }
    }
    function addEvent(type, paramEvent, selector) {
        var execute = function (e) {
            selector.removeEventListener(paramEvent.e, execute);
            update(type, getEventScore(type, sfvState.counts[type], e, paramEvent.g, throttling));
            if (typeof (sfvState.counts[type]) != 'undefined' && sfvState.counts[type] < throttling) {
                setTimeout(function () {
                    selector.addEventListener(paramEvent.e, execute);
                }, paramEvent.t * 1000);
                sfvState.counts[type]++;
            }
        }
        return selector.addEventListener(paramEvent.e ? paramEvent.e : paramEvent.w, execute);
    }
    function getEventScore(type, f, e, grade, sampleSize) {
        var currentValue = __sfvScore[type] ? __sfvScore[type] : 0;
        if (e.type == 'keyboard') return e.keycode.indexOf('Arrow') > -1 ? 0.1 : 1;
        if (e.type == 'scroll') return currentValue < ((document.documentElement.scrollTop || document.body.scrollTop) / document.body.scrollHeight) * grade ? ((document.documentElement.scrollTop || document.body.scrollTop) / document.body.scrollHeight) * grade : currentValue;
        if (e.type == 'click') return currentValue += (/input|select|a|button/gi.test(e.target.nodeName) ? 1 : 0.1) * grade / sampleSize;
        return f * grade / sampleSize;
    }
    var sfvEvents = [{ tn: 'window', w: 'focus', c: function () { return document.hasFocus() }, g: 10, t: 2 }, { tn: 'window', w: 'blur', g: 10, t: 2 }, { e: 'scroll', g: 10, t: .2 }, { e: 'mousemove', g: 10, t: .2 }, { e: 'click', g: 10, t: .2 }, { e: 'mousemove', g: 10, t: .2 }, { e: 'keydown', g: 10, t: 1 }, { e: 'touchmove', g: 10, t: 1 }, {
        tn: 'automation',
        c: function () {
            var l1 = ["__webdriver_evaluate", "__selenium_evaluate", "__webdriver_script_function", "__webdriver_script_func", "__webdriver_script_fn", "__fxdriver_evaluate", "__driver_unwrapped", "__webdriver_unwrapped", "__driver_evaluate", "__selenium_unwrapped", "__fxdriver_unwrapped"];
            var l2 = ["_phantom", "__nightmare", "_selenium", "callPhantom", "callSelenium", "_Selenium_IDE_Recorder"];
            var l4 = ["selenium", "webdriver", "driver"];
            for (var l2key in l2) if (window[l2[l2key]]) return true;
            for (var l1key in l1) if (window['document'][l1[l1key]]) return true;
            for (var l3key in window['document']) if (l3key.match(/\$[a-z]dc_/) && window['document'][l3key]['cache_']) return true;
            if (window['external'] && window['external'].toString() && (window['external'].toString()['indexOf']('Sequentum') != -1)) return true;
            for (var l4key in l4) if (window['document']['documentElement']['getAttribute'](l4[l4key])) return true;
            return false;
        }, g: 10, t: 0
    }];
    for (var paramEventKey in sfvEvents) {
        var paramEvent = sfvEvents[paramEventKey];
        var paramName = paramEvent.tn ? paramEvent.tn : (paramEvent.e ? paramEvent.e : paramEvent.w);
        if (paramEvent.e || paramEvent.w) {
            sfvState.events[paramName] = addEvent(paramName, paramEvent, paramEvent.e ? document : window);
            sfvState.counts[paramName] = 0;
        }
        if (paramEvent.c && typeof (paramEvent.c) == 'function') {
            if (paramEvent.c()) update(paramName, paramEvent.g);
        }
    }
})();
function setMasterId() {
    let userId = localStorage.getItem('MASTERID');
    if (!userId) {
        userId = masterKey + setRandomId();
        localStorage.setItem('MASTERID', userId);
    } else {
        //console.log('Existing MASTERID found:', userId);
    }
}